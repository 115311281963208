<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <!--선해임현황 기본정보-->
          <c-card title="LBL0010221" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-show="editable && popupParam.seniorStatusId && !isRevision && !hideButton"
                  label="개정"
                  icon="restart_alt"
                  @btnClicked="SetRevision" />
                <c-btn
                  v-show="editable && popupParam.seniorStatusId && isRevision && !hideButton"
                  label="개정취소"
                  icon="restart_alt"
                  @btnClicked="CancelRevision" />
                <!--삭제-->
                <c-btn
                  v-show="editable && popupParam.seniorStatusId && !hideButton"
                  label="LBLREMOVE"
                  icon="remove"
                  @btnClicked="deleteData"/>
                <!--저장-->
                <c-btn
                  v-show="editable && !hideButton"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <!--선해임명-->
                <c-text
                  required
                  :editable="editable && !hideButton"
                  :disabled="true"
                  :afterIcon="[
                    { name: 'search', click: true, callbackName: 'searchSenior' },
                    { name: 'close', click: true, callbackName: 'removeSenior' }
                  ]"
                  label="LBL0010222"
                  name="seniorName"
                  v-model="data.seniorName"
                  @searchSenior="searchSenior"
                  @removeSenior="removeSenior">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <c-text
                  name="seniorDeadline"
                  label="선임기한"
                  :disabled="true"
                  v-model="data.seniorDeadline"
                ></c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <c-text
                  name="qualifiyRequire"
                  label="선임자격"
                  :disabled="true"
                  v-model="data.qualifiyRequire"
                ></c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <c-plant
                  required
                  :editable="editable"
                  label="선임사업장"
                  type="edit"
                  name="plantCd"
                  v-model="data.plantCd">
                </c-plant>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
                <c-field 
                  required
                  type="dept_user" 
                  :editable="editable"
                  :disabled="hideButton"
                  :data="data"
                  deptValue="seniorDeptCd"
                  label="선임자" 
                  name="seniorUserId" 
                  v-model="data.seniorUserId" />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
                <!--선임일-->
                <c-datepicker
                  required
                  label="LBL0010217"
                  :editable="editable"
                  :disabled="hideButton"
                  name="seniorDate"
                  v-model="data.seniorDate"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
                <!--해임일-->
                <c-datepicker
                  :editable="editable"
                  :disabled="data.dismissalFlag !== 'Y' || hideButton"
                  label="LBL0010219"
                  :start="data.seniorDate"
                  name="dismissalDate"
                  v-model="data.dismissalDate"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
                <!--관련자격증-->
                <c-text
                  :editable="editable"
                  :disabled="hideButton"
                  label="LBL0010225"
                  name="relatedCertificate"
                  v-model="data.relatedCertificate">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
                <!--자격증번호-->
                <c-text
                  :editable="editable"
                  :disabled="hideButton"
                  label="LBL0010226"
                  name="certificateNo"
                  v-model="data.certificateNo">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
                <!--자격취득일-->
                <c-datepicker
                  :editable="editable"
                  :disabled="hideButton"
                  label="LBL0010227"
                  name="certificateAcquiDate"
                  v-model="data.certificateAcquiDate">
                </c-datepicker>
              </div>
              <div v-show="editable && popupParam.seniorStatusId && isRevision" class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-select
                  type="edit"
                  :required="editable && isRevision"
                  :editable="editable && isRevision"
                  itemText="codeName"
                  itemValue="code"
                  name="seniorRevTypeCd"
                  label="개정사유"
                  codeGroupCd="SENIOR_REV_TYPE_CD"
                  v-model="data.seniorRevTypeCd"
                  @datachange="revTypeChange"
                ></c-select>
              </div>
              <div v-show="editable && popupParam.seniorStatusId && isRevision && showRevContent" class="col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
                <c-text
                  :required="editable && isRevision && showRevContent"
                  :editable="editable && isRevision && showRevContent"
                  label="개정 상세사유"
                  name="revisionContent"
                  v-model="data.revisionContent">
                </c-text>
              </div>
              <!--관련 법규-->
              <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <c-multi-select
                  :editable="editable"
                  :isArray="false"
                  codeGroupCd="REGULATION_BILL_API_CD"
                  itemText="codeName"
                  itemValue="code"
                  label="LBL0010215"
                  :disabled="true"
                  name="relatedLaws"
                  maxValues="3"
                  v-model="data.relatedLaws">
                </c-multi-select>
              </div> -->
              <!-- <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-textarea
                  name="relatedReason"
                  label="관련근거"
                  :disabled="hideButton"
                  :rows="2"
                  v-model="data.relatedReason"
                ></c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-textarea
                  name="seniorReason"
                  label="수행역할"
                  :disabled="hideButton"
                  :rows="2"
                  v-model="data.seniorReason"
                ></c-textarea>
              </div> -->
            </template>
          </c-card>
        </div>
        <!--관련 교육과정 및 이수일-->
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-table
            ref="table"
            title="LBL0010231"
            :columns="grid.columns"
            :data="data.eduStatusList"
            gridHeight="340px"
            :filtering="false"
            :isExcelDown="false"
            :checkClickFlag="false"
          >
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"  id="revdiv">
          <c-table
            ref="revision"
            title="선해임이력 목록"
            tableId="revision"
            topBorderClass="topcolor-orange"
            :columns="gridrev.columns"
            :data="gridrev.data"
            gridHeight="340px"
            :columnSetting="false"
            :expandAll="false"
            :isFullScreen="false"
            :usePaging="false"
            :hideBottom="true"
            :filtering="false"
            @rowClick="rowClickRev"
          >
          </c-table>
        </div>
        <template>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 q-mt-md">
            <c-upload 
              :attachInfo="attachInfo"
              :editable="editable">
            </c-upload>
          </div>
        </template>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </q-form>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'senior-dismissal-status-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        seniorStatusId: '',
        seniorUserId: '',
      }),
    },
  },
  data() {
    return {
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'DISMISSAL_ATTACH',
        taskKey: '',
        beforeTaskKey: '',
        isRev: false,
      },
      selectedSeniorStatusId: null,
      grid: {
        columns: [
          {
            name: "educationCourseName",
            field: "educationCourseName",
            //교육과정
            label: "LBL0010233",
            style: 'width:250px',
            align: 'left',
            sortable: true,
          },
          {
            name: "educationCycleName",
            field: "educationCycleName",
            //교육주기
            label: "LBL0010234",
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            name: "evaluationDate",
            field: "evaluationDate",
            //최근 교육이수일
            label: "LBL0010235",
            style: 'width:120px',
            align: 'center',
            sortable: true,
          },
          {
            name: "eduNextDate",
            field: "eduNextDate",
            //차기 교육이수일
            label: "LBL0010236",
            style: 'width:120px',
            align: 'center',
            sortable: true,
          },
        ],
        data: [],
      },
      mappingType: 'POST',
      saveUrl: '',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      revlistUrl: '',
      editable: true,
      isSave: false,
      isRevision: false,
      data: {
        plantCd: null,
        seniorStatusId: '',
        relatedLaws: '',
        seniorName: '',
        qualifiyRequire: '',
        seniorDeadline: '',
        seniorUserId: '',
        changeSeniorUserId: '',
        seniorDate: '',
        seniorDeptCd: '',
        seniorDeptName: '',
        relatedCertificate: '',
        dismissalDate: '',
        certificateNo: '',
        certificateAcquiDate: '',
        relatedReason: '',
        seniorReason: '',
        revisionNum: '',
        groupId: '',
        dismissalFlag: 'N',
        sysRevision: 1,
        seniorRevTypeCd: null,
        seniorRevTypeName: '',
        revisionContent: '최초선임',
        seniorId: '',
        regDtStr: '',
        regUserName: '',

        eduStatusList: [],
      },
      dismissalFlagItems: [
        { code: 'Y', codeName: 'YES' },
        { code: 'N', codeName: 'NO' },
      ],
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      gridrev: {
        columns: [
          {
            name: 'revisionNum',
            field: 'revisionNum',
            //개정번호
            label: 'LBLREVNO',
            align: 'center',
            style: 'width: 80px',
            sortable: true,
          },
          {
            name: 'seniorRevTypeName',
            field: 'seniorRevTypeName',
            label: '개정사유',
            style: 'width: 200px',
            align: 'left',
            sortable: true,
          },
          {
            name: 'revisionContent',
            field: 'revisionContent',
            label: '개정 상세사유',
            style: 'width: 200px',
            align: 'left',
            sortable: true,
          },
          {
            name: 'regDtStr',
            field: 'regDtStr',
            //개정일시
            label: 'LBLREVPERIOD',
            style: 'width: 150px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'regUserName',
            field: 'regUserName',
            //개정자
            label: 'LBLREVUSER',
            align: 'center',
            style: 'width: 100px',
            sortable: true,
          },
        ],
        data: [],
      },
    };
  },
  computed: {
    hideButton() {
      return Boolean(this.data.dismissalDate)
    },
    showRevContent() {
      return this.data.seniorRevTypeCd == 'SRTC000001'
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.mdm.senior.status.get.url;
      this.saveUrl =  transactionConfig.mdm.senior.status.insert.url,
      this.insertUrl = transactionConfig.mdm.senior.status.insert.url;
      this.updateUrl = transactionConfig.mdm.senior.status.update.url;
      this.deleteUrl = transactionConfig.mdm.senior.status.delete.url;
      this.revlistUrl = selectConfig.mdm.senior.status.rev.url;
      this.getRevUrl = selectConfig.mdm.senior.status.rev.get.url;
      this.getDetail(true);
      // this.getData();
    },
    getDetail(_check) {
      if (this.popupParam.seniorStatusId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.seniorStatusId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
          this.$set(this.attachInfo, 'taskKey', this.popupParam.seniorStatusId)
          this.isRevision = false;
        if (_check) {
          this.getRevList(_result.data.groupId);
        }
        },);
      }
    },
    saveData() {
      if (this.popupParam.seniorStatusId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      let saveMsg = 'MSGSAVE';  // 저장하시겠습니까?
      if (this.isRevision) {
        saveMsg = 'MSGREVSAVE';
        // 현재버전을 개정하여 저장하시겠습니까?
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',  // 확인
            message: saveMsg,
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              // 개정시
              if (this.isRevision) {
                this.saveUrl = this.insertUrl;
                this.mappingType = 'POST';
                this.data.sysRevision = parseInt(this.data.sysRevision) + 1;
                if (this.popupParam.seniorUserId != this.data.seniorUserId) {
                  this.data.changeSeniorUserId = this.popupParam.seniorUserId;
                } else {
                  this.data.changeSeniorUserId = ''
                }
              }
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',  // 확인
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.data.groupId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (!this.popupParam.seniorStatusId) {
        this.$set(this.attachInfo, 'taskKey', this.popupParam.seniorStatusId)
        this.$set(this.attachInfo, 'isSubmit', uid())
      }
      this.popupParam.seniorStatusId = result.data
      /**
       * 첨부파일 셋팅
       */
      this.$set(this.attachInfo, 'beforeTaskKey', '')
      this.$set(this.attachInfo, 'isRev', false)
      this.getDetail(true);
    },
    searchSenior() {
      this.popupOptions.title = 'LBL0010237'; // 선해임명 검색
      this.popupOptions.param = {
        type: 'single',
      };
      this.popupOptions.target = () => import(`${'./seniorDismissalPop.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeSeniorPopup;
    },
    closeSeniorPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.data.relatedLaws = data[0].relatedLaws;
        this.data.seniorName = data[0].seniorName;
        this.data.qualifiyRequire= data[0].qualifiyRequire;
        this.data.seniorClassCd = data[0].seniorClassCd;
        this.data.seniorFieldCd = data[0].seniorFieldCd;
        this.data.seniorId = data[0].seniorId;
      }
    },
    removeSenior() {
      this.data.relatedLaws = '';
      this.data.seniorName = '';
      this.data.qualifiyRequire = '';
      this.data.seniorClassCd = '';
      this.data.seniorFieldCd = '';
      this.data.seniorId = '';
    },
    datachange() {
      this.data.dismissalDate = ''
    },
    SetRevision() {
      this.$http.url = selectConfig.com.revision.getversion;
      this.$http.param = {revisionNum: this.data.revisionNum}
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data.revisionNum = _result.data;
        this.data.seniorRevTypeCd = null;
        this.data.revisionContent = '';
        this.data.regDtStr = '';
        this.data.regUserName = '';
        this.isRevision = true;
        /**
         * 첨부파일 셋팅
         */
        this.$set(this.attachInfo, 'taskKey', '')
        this.$set(this.attachInfo, 'beforeTaskKey', this.popupParam.seniorStatusId)
        this.$set(this.attachInfo, 'isRev', true)
      });
    },
    CancelRevision() {
      this.isRevision = false;
      /**
       * 첨부파일 셋팅
       */
      this.$set(this.attachInfo, 'taskKey', this.popupParam.seniorStatusId)
      this.$set(this.attachInfo, 'beforeTaskKey', '')
      this.$set(this.attachInfo, 'isRev', false)
      this.getDetail();
    },
    getRevList(_groupId) {
      this.$http.url = this.revlistUrl;
      this.$http.type = 'GET';
      this.$http.param = {groupId: _groupId};
      this.$http.request((_result) => {
        this.gridrev.data = _result.data;
      },);
    },
    rowClickRev(row) {
      if (row) {
        if (row.seniorStatusId != this.popupParam.seniorStatusId) {
          this.popupParam.seniorStatusId = row.seniorStatusId
          // this.getDetail(this.data.seniorStatusId, true);
          this.getDetail(false);
          this.$set(this.attachInfo, 'taskKey', row.seniorStatusId)
        }
      }
    },
    revTypeChange() {
      if (this.data.seniorRevTypeCd === 'SRTC000001') {
        this.$set(this.data, 'seniorUserId', '');
        this.$set(this.data, 'seniorDate', '');
      } else {
        //
      }
    },
  }
};
</script>
